import React, { Component } from "react";
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import "./AddDevice.css";

const FRAME_ENCRYPTION=0x01;
const FRAME_CHECKSUM=0x02;
const FRAME_ESP2PHONE=0x04;
const FRAME_REPLY_ACK=0x08;
const FRAME_DATA_FRAGMENTS=0x10;


const SET_SSID=9
const SET_PASSWORD=13
const CONNECT=12
const DISCONNECT=16
const CONSTAT=20
const WIFICONREPORT=61
const DISCONBLE=32
const CUSTOM=0x4d
const REPORT_ERROR=0x49


function bin2string(array){
	var result = "";
	for(var i = 0; i < array.length; ++i){
		result+= (String.fromCharCode(array[i]));
	}
	return result;
}


function stringToAsciiByteArray(str)
{
    var bytes = [];
   for (var i = 0; i < str.length; ++i)
   {
       var charCode = str.charCodeAt(i);
      if (charCode > 0xFF)  // char > 1 byte since charCodeAt returns the UTF-16 value
      {
          throw new Error('Character ' + String.fromCharCode(charCode) + ' can\'t be represented by a US-ASCII byte.');
      }
       bytes.push(charCode);
   }
    return bytes;
}

export default class Blufi extends Component {
  constructor(props) {
    super(props);
    this.steps = ['Connect to device', 'Scan for networks', 'Connect'];
      this.state = {
        step:0,
        steps:["Connect to device", "Setup Network"],
        message:"",
        state:"",
        error:"",
        device:{},
        counter:0,
        messagerd:"",
        wifilist:[],
        scan_state:false,
        ble_con_state:"",
        wifi_constate:"---",
        wifi_connecting:false,
        SSID:"",
        PASSWORD:"",
        counter2:11,
        log:[],
        packetfrag:[],
        connectResult:99
      };
      this.msgList={}
  }

  connectionCheck(){
    console.log("Conenction State??")
  this.write(CONSTAT,[])
    clearInterval(this.timerID);
  }


    componentWillUnmount() {
      clearInterval(this.timerID);
    }

  handleChangeSSID=(event)=> {
     this.setState({SSID: event.target.value});
   }

   handleChangePASSWORD=(event)=> {
      this.setState({PASSWORD: event.target.value});
    }
       handleNext = () => {
         let step=this.state.step+1
         this.setState({"step":step})

       };

       handleBack = () => {
         let step=this.state.step-1
         this.setState({"step":step})
       };


       handleBackAbort=()=>{
         //Stop the current attempt
         this.write(DISCONNECT,[])
         this.handleBack()
       }

       handleWifiConnect=()=>{
         this.setState({"connectResult":1})
         this.setState({"wifi_constate":"Sending SSID..."})
         this.setState({"wifi_connecting":true})
         this.handleNext()
          this.write(SET_SSID,stringToAsciiByteArray(this.state.SSID))

    //      this.write(SET_PASSWORD,stringToAsciiByteArray(this.state.PASSWORD))


    //      this.write(CONNECT,[])
         //Wait some time then send onClick={()=>this.write(CONSTAT,[])
       }

       sendSSID(){

           this.write(SET_SSID,stringToAsciiByteArray(this.state.SSID))
       }
       sendPassword(){
         this.write(SET_PASSWORD,stringToAsciiByteArray(this.state.PASSWORD))
       }


      connect(){
        let serviceUuid="0000ffff-0000-1000-8000-00805f9b34fb"
        let characteristicUuid=0xff01
        let app=this
        app.setState({state:"Connecting..."})
        //Reset the counter
        app.setState({counter:0})


        this.device.gatt.connect().then(server => {
         console.log('Getting Service...');
         app.setState({ble_con_state:"Getting services"});
         return server.getPrimaryService(serviceUuid);
        }).then(service => {
        app.service=service
        return service.getCharacteristic(characteristicUuid);
        }).then(characteristic => {
          app.setState({message:'Getting descriptors...'})

          app.writeChar=characteristic
          return app.service.getCharacteristic(0xff02);
        }).then(characteristic=>{
        app.reader=characteristic
        return characteristic.startNotifications()
        }).then(_=>{
        app.reader.addEventListener('characteristicvaluechanged',(event)=>{
         console.log("HANDLE NOTIFICATIONS")
         console.log(event.target.value)
         let value=event.target.value
         let a=[]
         let a_int=[]
         for (let i = 0; i < value.byteLength; i++) {
            a.push(' 0x' + ('00' + value.getUint8(i).toString(16)).slice(-2));
            a_int.push(parseInt(value.getUint8(i)))
          }
          console.log(this)
          this.setState({"messagerd":a})
          console.log(a)
            app.process_msg(a_int)
        });
        app.setState({state:'Connected'})
        app.setState({ble_con_state:"Connected"})
        if(app.state.step==0){
          app.handleNext();
        }
        app.device.addEventListener('gattserverdisconnected',()=>{ app.connect()});

        })
        .catch(error => {
         app.setState({state:"No Longer Connected"})

         console.log('Argh! ' + error);
        });


      }


    BLEinitconnect(){
      let app=this

      try{
        this.device.gatt.disconnect()
         app.setState({ble_con_state:""})
      }catch(err){
        console.log("Cant disconnect")
      }
      let filters = [];
      let serviceUuid="0000ffff-0000-1000-8000-00805f9b34fb"
      let characteristicUuid=0xff01
      let filterService =""

      let filterNamePrefix = "BLU"
       if (filterNamePrefix) {
         filters.push({namePrefix: filterNamePrefix});
       }
       let options = {};
       options.acceptAllDevices = true;
      // options.filters = filters;
       options.optionalServices= [serviceUuid]
         //Console.log
       console.log('Requesting Bluetooth Device...');
       console.log('with ' + JSON.stringify(options));
       navigator.bluetooth.requestDevice(options).then(device => {
         app.setState({ble_con_state:"searching..."})
         app.setState({message:"Connected"})
         console.log('> Name:             ' + device.name);
         console.log('> Id:               ' + device.id);
         console.log('> Connected:        ' + device.gatt.connected);
         app.device=device
         app.setState({state:"Connecting..."})
         app.setState({ble_con_state:"Connecting..."})
         app.connect()
      })
    }

    handleNotifications(event){

   // 00010000  16
    }



    process_msg(msg){

      console.log("PROCESS")
      console.log(msg)


  //0x3d", " 0x04", " 0x04", " 0x03", " 0x01", " 0x01", " 0x00
  //0x3d 0x04 0x1f 0x03 0x01 0x01 0x00  NOT CONNECTED
  //0x3d 0x04 0x22 0x03 0x01 0x01 0x00

    let logtemp=this.state.log
    logtemp.push(msg.toString())
    this.setState({log:logtemp})

  //[" 0x3d", " 0x04", " 0x05", " 0x15", " 0x01", " 0x00", " 0x00", " 0x01", " 0x06", " 0x2c", " 0x30", " 0x33", " 0x2f", " 0xab", " 0x9e", " 0x02", " 0x08", " 0x61", " 0x61", " 0x72", " 0x64", " 0x76", " 0x61", " 0x72", " 0x6b"]
  //[" 0x3d", " 0x04", " 0x06", " 0x15", " 0x01", " 0x00", " 0x00", " 0x01", " 0x06", " 0x2c", " 0x30", " 0x33", " 0x2f", " 0xab", " 0x9e", " 0x02", " 0x08", " 0x61", " 0x61", " 0x72", " 0x64", " 0x76", " 0x61", " 0x72", " 0x6b"]
  if(msg[0]==WIFICONREPORT){
  //  let len msg[4]
    //61, 4, 40, 3, 1, 1, 0]

    // msg[5] mode 01 STA 02 SoftAP 03 SoftAP sta
    // msg[6] constate 0 == cpnnected
      this.setState({connectResult:msg[5]})
      clearInterval(this.timerID);
      if(msg[5]==0 | msg[5]==97){
        //Connected
        this.setState({"wifi_constate":"Connected"}) //Can we get the IP?
        this.setState({wifi_connecting:false})
      }
      else{
        this.setState({"wifi_constate":"Failed to connect "+msg[5]})
        this.setState({wifi_connecting:false})
      }




  }



      if(msg[0]==REPORT_ERROR){
        console.log(this.msgList)
        if(msg[4]==0){
          //Frame error
          //Send again
          alert("comms sync error, please disconnect")
        //  this.setState({"counter":0})


        }

      }

      if(msg[0]==0x00 & msg[1]==0x04){
        console.log("ACK FOR MESSAGE count should be"+msg[2])
        console.log("Count is at :"+this.state.counter)
          try{
            this.msgList[msg[4]]["ack"]=true

            if(this.msgList[msg[4]]["cmd"]==36){
            this.setState({scan_state:true})
          }

            if(this.msgList[msg[4]]["cmd"]==SET_SSID){ //we just sent the SSID OK, lets sent the password
              this.sendPassword()
              this.setState({"wifi_constate":"Sending password..."})
            }
            if(this.msgList[msg[4]]["cmd"]==SET_PASSWORD){//We sent the SSID OK, lets send connect
              this.write(CONNECT,[])
              this.setState({"wifi_constate":"Initiating connection..."})
              this.timerID = setInterval(
                () => this.connectionCheck(),
                5000
              );

            }
            //We then may want to to constatus

          }catch(err){
              console.log(err)
          }
          console.log(this.msgList)
          console.log(msg)
      }



      if(msg[0]==0x45){
        if(msg.length==5){
          this.setState({scan_state:true})
          this.setState({"state":"Scanning"})
        return
      }
        console.log("WIFI LIST RESPONSE")

  			if( (FRAME_DATA_FRAGMENTS&msg[1])==FRAME_DATA_FRAGMENTS){
  				//Its a gragmented packet
  				let temp=this.state.packetfrag
  				for(var i=6;i<msg.length;i++){
  					temp.push(msg[i])
  				}
  				this.setState({packetfrag:temp})


  			}else{
  			if(this.state.packetfrag.length>0){
  				let temp=this.state.packetfrag
  				for(var i=4;i<msg.length;i++){
  					temp.push(msg[i])
  				}

  				msg[3]=temp.length
  				msg=msg.slice(0,4)
  				msg=msg.concat(temp)
  		//		alert(msg.toString())
  				//Restore the whole packet
  			this.setState({packetfrag:[]})
  			}


        let data_len=msg[3]
        let wifidata=msg.slice(4,4+data_len)
        console.log("WIFIDATA")
        console.log(wifidata)
        var i=0
        var d=[]
        while(i<wifidata.length){
          var ssid_len=wifidata[i]
          console.log(ssid_len)
          var ssid_strength=wifidata[i+1]
          var ssid=bin2string(wifidata.slice(i+2,i+1+ssid_len))
          d.push({"ssid":ssid,"rssi":ssid_strength})
          i=i+ssid_len
          i++
        }
        console.log(d)
        this.setState({scan_state:false})
        this.setState({wifilist:d})
  		}
      }
    }

    scan(){
        this.setState({"wifilist":[]})
        this.write(36,[])
    }


    write(cmd,data){
        let app=this
        if (this.writeChar==null){
          this.setState({"state":"Not connected"})
          return
        }else{
            //Type 4C
            // Control
            //Sequnce number
            //Data Length
            //Data
            //Checksum if enabled!!!


            //32 disconnect

  //20 current mode

    console.log("Incomming data:"+data)
    console.log(data)
            var controlFrame=controlFrame|FRAME_REPLY_ACK
            var data_write=[cmd,controlFrame,app.state.counter,data.length]
            data_write=data_write.concat(data);

            this.msgList[app.state.counter]={"cmd":cmd,"ack":false}
            console.log("DATA is:")
            console.log(data_write)
            var value = new Uint8Array(data_write);

            app.setState({counter:this.state.counter+1})


                this.writeChar.writeValue(value).then(_ => {
                console.log("Handle Data")
                //Read back what was written
                let v2=new DataView(value.buffer)
                let a = [];
                let a_int=[]
                for (let i = 0; i < v2.byteLength; i++) {
                  a.push('0x' + ('00' + v2.getUint8(i).toString(16)).slice(-2));

                }

                app.setState({message:a+" ("+value.byteLength+")"})
            }).catch(error=>{
                console.log("Error sending message")
                //app.setState({"ble_con_state":"Disconnected :("})
                //app.BLEinitconnect()
                //alert("Communication to device has failed, attempting reconnect")

  })
        }
  }

  loadssid(ssid){
  console.log("Load ssid")
  this.setState({"SSID":ssid})


  }

  rebootDevice(){
    this.write(CUSTOM,[0x52])//R
  }


  clearDevice(){
    this.write(CUSTOM,[0x43])//C
  }

  BlinkLeds(){
  this.write(CUSTOM,[0x42])//B
  }

  forceSave(){
  this.write(CUSTOM,[0x46])//F
  }
  read(){

  /*  let app=this
    this.state.device.gatt.getPrimaryService("0000ffff-0000-1000-8000-00805f9b34fb")
    .then(service => service.getCharacteristic("0000ff02-0000-1000-8000-00805f9b34fb"))
    .then(characteristic=>characteristic.startNotifications()).then(function(BluetoothRemoteGATTCharacteristic) { alert("**8") })
    */

    let app=this
    this.state.device.gatt.getPrimaryService("0000ffff-0000-1000-8000-00805f9b34fb")
    .then(service => service.getCharacteristic("0000ff02-0000-1000-8000-00805f9b34fb"))
    .then(characteristic => characteristic.readValue() ).then(value=>{
      let v2=new DataView(value.buffer)
      let values=[]
      for(var i=0;i<value.byteLength;i++){
        values.push('0x' + ('00' + v2.getUint8(i).toString(16)).slice(-2));
      }
         app.setState({message:values+" ("+value.byteLength+")"})

    });

  //app.setState({"message":characteristic.readValue()}

    /*
          let app=this
    app.readAck.readValue().then(value => {
       let values=[]
       for(var i=0;i<value.byteLength;i++){
         values.push('0x' + ('00' + value.getUint8(i).toString(16)).slice(-2));
       }
          app.setState({message:values+" ("+value.byteLength+")"})
      })
      .catch(error => {
        app.setState({error:'read error...'})
        console.log('Argh! ' + error);
      });
      */
  }
  render(){
    return (<Card className="cardPad">

    <Stepper activeStep={this.state.step} orientation="vertical">


            <Step key="step1">
            <StepLabel>Connect to device</StepLabel>
            <StepContent>Find device using webluetooth
            <br/>
     <center><Button onClick={()=>this.BLEinitconnect()} variant="contained" color="primary">Connect</Button>
     <br/>
     <br/>
     <span>{this.state.ble_con_state}</span>

     </center>



 <br/>
 <br/>
           {/*  <Button onClick={this.handleNext}>Next</Button>*/}
            </StepContent>

            </Step>

            <Step key="step2">



            <StepLabel>Wifi Setup



            </StepLabel>
            <StepContent><Button onClick={this.handleBack}>Go Back</Button>


 <center><Button  onClick={()=>this.scan()} variant="contained" color="primary">Scan for networks</Button></center>

 <br/>
 <br/>

 {this.state.wifilist.length!=0 ?(
   <center>
  <InputLabel htmlFor="wifi-simple">Select Network</InputLabel>

  <Select
             placeholder=" Select SSID"
            value={this.state.SSID}
            onChange={this.handleChangeSSID}
            inputProps={{
              name: 'wifi',
              id: 'wifi-simple',
            }}
          >

          {this.state.wifilist.map((e,i)=>
             <MenuItem key={i} value={e.ssid}>{e.ssid} (rssi:-{255-e.rssi})</MenuItem>
          )}
          </Select>
          </center>
 ):(
   <center>

   {this.state.scan_state==true?(
  <CircularProgress />):(<span></span>)}
   </center>
 )}

 <br/>
 <br/>
 <br/>
 <TextField

         label="SSID"
         style={{ margin: 8 }}
         placeholder="Wifi SSID"
         helperText=""
         fullWidth
         margin="normal"
  value={this.state.SSID}
   onChange={this.handleChangeSSID}
         InputLabelProps={{
           shrink: true,
         }}
       />

       <TextField

         label="Password"
         style={{ margin: 8 }}
         placeholder="Wifi Password"
         helperText=""
         fullWidth
         margin="normal"
         type="password"
 value={this.state.PASSWORD}
  onChange={this.handleChangePASSWORD}
         InputLabelProps={{
           shrink: true,
         }}
       />




 <Button onClick={this.handleWifiConnect}>Connect</Button>
            </StepContent>
            </Step>
            <Step key="step3">
            <StepLabel>Setup Connection</StepLabel>
            <StepContent>

            <span>{this.state.wifi_constate}</span>
            <center>
            {this.state.wifi_connecting ==true? (<CircularProgress />):(

               <div>
            {this.state.connectResult==0?(
              <center>
              <Button onClick={()=>this.rebootDevice()} variant="contained" color="primary">Finish Setup</Button><br/><br/>
              <span>Device will start normal opperation.</span>
              </center>

            ):(<span></span>)}
 </div>
          )}

            </center>






 <Button onClick={this.handleBackAbort}>Go Back</Button>
 <Button onClick={()=>this.clearDevice()} >Clear Changes</Button>
 <Button onClick={()=>this.forceSave()} title="save the provided settings if network is not visable">Force Save</Button>
            </StepContent>
            </Step>



     {/*       <Step key="step4">
            <StepLabel>Finished</StepLabel>
            <StepContent>


            <center>
            <CircularProgress />
            </center>


            </StepContent>
            </Step>
 */}







          </Stepper>





    </Card>)

  }


}
