import React, { Component } from "react";

import "./Home.css";
import AWS from 'aws-sdk';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';


export default class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      md_auth:null,
      md_unauth:null
    };
  }
  render(){
  return (
  <Card className="cardPad">
    <div className="Home">

      <div className="lander">
        <h1 className="mainTitleBig">OOK</h1>
          {this.props.isAuthenticated ? <span><Button href="/devices" variant="outlined" color="primary">Control</Button></span>:<span> <Button color="primary" href="/signup">Signup</Button>
           <Button color="primary" href="/login">Login</Button></span>}
      </div>

    </div>
    </Card>
  );
}
}
