import React, { Component } from "react";
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Paper from '@material-ui/core/Paper';

import "./AddDevice.css";

export default class AddDevice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      devices:[],
      editmode:false,
      saving:false
    };
  }

  render(){
    return (<Card className="cardPad">

          <div className="AddDiv">

          <h3>Add New Device</h3>



              <Button variant="contained" color="primary"  href="/add/socket">Socket</Button>&nbsp;


              <Button variant="contained" color="primary" href="/add/serial">Serial</Button>



          </div>








    </Card>)

  }


}
