import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./containers/Home";

import Devices from "./containers/Devices";
import AddDevice from "./containers/AddDevice";
import Markdown from "./containers/Markdown";
import AddSocket from "./containers/AddSocket";
import AddSerial from "./containers/AddSerial";

import NotFound from "./containers/NotFound";
import AppliedRoute from "./components/AppliedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";
import AuthenticatedRoute from "./components/AuthenticatedRoute";


import Login from "./containers/Login";
import ResetPassword from "./containers/ResetPassword";
import ChangeEmail from "./containers/ChangeEmail";
import ChangePassword from "./containers/ChangePassword";
import Signup from "./containers/Signup";
import Settings from "./containers/Settings";

import Blufi from "./containers/Blufi";



export default ({ childProps }) =>
    <Switch>

      <AppliedRoute path="/" exact component={Home} props={childProps} />


      <AppliedRoute path="/Blufi"  exact component={Blufi} props={childProps} />
      <AppliedRoute path="/md/:id"  exact  component={Markdown}  props={childProps}/>
      <UnauthenticatedRoute path="/signup" exact component={Signup} props={childProps} />
      <UnauthenticatedRoute path="/login" exact component={Login} props={childProps} />
      <UnauthenticatedRoute path="/login/reset"  exact  component={ResetPassword}  props={childProps}/>

      <AuthenticatedRoute path="/settings"  exact  component={Settings}  props={childProps}/>
      <AuthenticatedRoute path="/devices"  exact  component={Devices}  props={childProps}/>
      <AuthenticatedRoute path="/settings/email" exact component={ChangeEmail}  props={childProps} />
      <AuthenticatedRoute path="/settings/password"  exact component={ChangePassword} props={childProps} />



      <AuthenticatedRoute path="/add"  exact component={AddDevice} props={childProps} />
      <AuthenticatedRoute path="/add/socket"  exact component={AddSocket} props={childProps} />
      <AuthenticatedRoute path="/add/serial"  exact component={AddSerial} props={childProps} />

      <Route component={NotFound} />
    </Switch>
