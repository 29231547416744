import React, { Component } from "react";
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';


import { API } from "aws-amplify";
import "./AddDevice.css";


function AlexaName(props){
    if(props.name.length==0){
      return (<b>______</b>)
    }else{
      return (<b>{props.name}</b>)
    }
    return "error"
}


export default class AddDevice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      devices:[],
      editmode:false,
      saving:false,
      step:0,
      resultON:" ",
      resultOFF:" ",
      newSocketName:""
    };
  }


  handleFinish=()=>{


    if(this.state.newSocketName==0){
      alert("Please Name socket")
      return;
    }else{


       let PostData={"type":"new","cmd":this.state.newSocketName}
        API.post("api", "api",{body:PostData}).then(response => {
          console.log(response)
          //Need to change the location,
          window.location="/devices"
        }).catch(error => {
            console.log(error.response)
        });



    }






  }


  handleNext = () => {
      let step=this.state.step+1
      this.setState({"step":step})

    };

    handleBack = () => {
      let step=this.state.step-1
      this.setState({"step":step})
    };



    learnFeedback = (cmd , result) =>{
        console.log("learn Feedback")
        if(cmd=="ON"){
            if(result==true){
              this.setState({"resultON":"Got ON code"})
            }else{
              this.setState({"resultON":"Didn't get a code, try again..."})
            }
        }
        if(cmd=="OFF"){
          if(result==true){
            this.setState({"resultOFF":"Got OFF code"})
          }else{
            this.setState({"resultOFF":"Didn't get a code, try again..."})
          }
        }

    }


    checkResult=(cmd,count)=>{
      console.log("Check Learn Result")
      count=count-1;
      console.log(this.state)

      let PostData={"type":"learnCheck","cmd":cmd}
      API.post("api", "api",{body:PostData}).then(response => {
        console.log(response)
        if(response=="got"+cmd){
            this.learnFeedback(cmd,true)
        }
        if(response=="notgot"+cmd){
            if(count==0){
              this.learnFeedback(cmd,false)
            }else{
                setTimeout(function(){this.checkResult(cmd,count)}.bind(this),1000);
            }
        }
        console.log(count)
      }).catch(error => {
          console.log(error.response)
      });
      }


handleTestON=()=>{
  let PostData={"SocketID":"TEMPON","cmd":"TurnOnRequest","type":"control"}
   API.post("api", "api",{body:PostData}).then(response => {
     console.log(response)
     //Need to change the location,
   }).catch(error => {
       console.log(error.response)
   });
}

handleTestOFF=()=>{
  let PostData={"SocketID":"TEMPOFF","cmd":"TurnOffRequest","type":"control"}
   API.post("api", "api",{body:PostData}).then(response => {
     console.log(response)
     //Need to change the location,
   }).catch(error => {
       console.log(error.response)
   });
}


    handleLearnON=(e)=>{
      console.log("Start Learn ON")
      let PostData={"cmd":"ON","type":"learn"}
      API.post("api", "api",{body:PostData}).then(response => {
        console.log(response)
        this.setState({"resultON":""})
        setTimeout(function(){this.checkResult("ON",7)}.bind(this),2000);
      }).catch(error => {
          console.log(error.response)
      });
      //Need do do a set timout to get the result

    }

    handleLearnOFF=(e)=>{
      console.log("Start Learn OFF")
      let PostData={"cmd":"OFF","type":"learn"}
      API.post("api", "api",{body:PostData}).then(response => {
        console.log(response)
        this.setState({"resultOFF":""})
        setTimeout(function(){this.checkResult("OFF",7)}.bind(this),2000);
      }).catch(error => {
          console.log(error.response)
      });
      //Need do do a set timout to get the result

    }


    handleChange = event => {
      this.setState({
        [event.target.id]: event.target.value
      });
    }

  render(){
    return (<Card className="cardPad">

          <div className="SocketDiv">
          <Stepper activeStep={this.state.step} orientation="vertical">


                  <Step key="step1">
                  <StepLabel>Get ready to pair</StepLabel>
                  <StepContent>
                  <br/>
                  <br/>
                  Before you start, have the socket and remote you want to pair with OOK working
                  <br/>
                  <br/>
                  Have the remote in reach and be close to the OOK so it recieves a strong signal (in the same room within a few meters)
                  <br/>
                  <br/>
           <center><Button variant="contained"  onClick={this.handleNext}>Next</Button>
           <br/>
           <br/>

           </center>
        <br/>
                 {/*  <Button onClick={this.handleNext}>Next</Button>*/}
                  </StepContent>

                  </Step>



                  <Step key="step2">



                  <StepLabel>Learning</StepLabel>

                  <StepContent>



Get ready to press the apropriate button on remote control when the light on your OOK changes to GREEN. Press firmly untill blue light returns
                  <Button onClick={this.handleBack}>Go Back</Button>
                  <br/>
                  <br/>

       <center>
          <Button  variant="contained" color="secondary" onClick={this.handleLearnON} >Learn ON</Button>
          <br/>
          <br/>
          {this.state.resultON.length==0 ?
              <CircularProgress color="secondary" size={20} />:
              <i>{this.state.resultON}</i>}
       </center>
       <br/>
       <br/>

       <center>
          <Button  variant="contained" color="primary" onClick={this.handleLearnOFF} >Learn OFF</Button>
          <br/>
          <br/>

          {this.state.resultOFF.length==0 ?
              <CircularProgress color="secondary" size={20} />:
              <i>{this.state.resultOFF}</i>}


       </center>

       <br/>
       <br/>

<center>
<Button variant="contained" onClick={this.handleBack}>Back</Button>&nbsp;
<Button variant="contained" onClick={this.handleNext}>Next</Button>
</center>







                  </StepContent>
                  </Step>

                  <Step key="step3">
                    <StepLabel>Testing</StepLabel>
                    <StepContent>


                      <p>Test that the learned codes can control your socket</p>
                      <center>

                      <Button  variant="contained" color="secondary" onClick={this.handleTestON} >Test ON</Button>
                      <br/>
                      <br/>

                      <Button  variant="contained" color="primary" onClick={this.handleTestOFF} >Test OFF</Button>

                      </center>

                      <p>if the socket didnt respond to the learned codes, <Button onClick={this.handleBack}>GO BACK</Button> and relearn the required button</p>



                      <center>
                      <Button variant="contained" onClick={this.handleBack}>Back</Button>&nbsp;
                      <Button variant="contained" onClick={this.handleNext}>Next</Button>
                      </center>


                    </StepContent>
                  </Step>


                  <Step key="step4">
                    <StepLabel>Finish setup</StepLabel>
                    <StepContent>


                    <b>Name the socket</b>
                    <br/>
                    <i>This name will also be used for control when paired with a voice assistant (it can be renamed after)</i>


  <TextField  id="newSocketName" label="Device Name" fullWidth value={this.state.newSocketName} onChange={this.handleChange}/>


  <br/>
  <br/>
  <center><i>
  <b>Alexa,</b> turn on the <AlexaName name={this.state.newSocketName} /><br/>

  <b>Alexa,</b> turn the <AlexaName name={this.state.newSocketName}  /> off
  </i></center>
  <br/>
  <br/>

                    <center>
                    <Button variant="contained" onClick={this.handleBack}>Back</Button>&nbsp;
                    <Button variant="contained" color="primary" onClick={this.handleFinish}>Finish</Button>
                    </center>

                    </StepContent>
                  </Step>





                  </Stepper>




          </div>








    </Card>)

  }


}
