import React, { Component } from "react";
import { LinkContainer } from "react-router-bootstrap";
import LoaderButton from "../components/LoaderButton";
import "./Settings.css";
import { Auth } from "aws-amplify";
import Card from '@material-ui/core/Card';


export default class Settings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      uid:""
    };

    console.log(Auth.currentCredentials())
    this.userinfo()
  }


  async userinfo(){

 const user = await Auth.currentCredentials();
 // /console.log(user)

this.setState({uid:user._identityId})


  }

  render() {
    return (
      <Card className="cardPad">
      <div className="Settings">
        <h3>Settings</h3>

        <h4>Devices</h4>
        <LinkContainer to="/blufi">
          <LoaderButton
            block
              variant="contained" color="primary"
            text="Setup/Configure OOK"
          />
        </LinkContainer><br/><br/>




        <h4>Account</h4>
        <LinkContainer to="/settings/email">
          <LoaderButton
            block
              variant="contained" color="secondary"
            text="Change Email"
          />
        </LinkContainer><br/><br/>
        <LinkContainer to="/settings/password">
          <LoaderButton
            block
              variant="contained" color="secondary"
            bsSize="large"
            text="Change Password"
          />
        </LinkContainer>

        <br></br><br/>
        {/*<i>unique identity<p>{this.state.uid}</p></i> */}

        </div>
        </Card>
    );
  }
}
