import React, { Component } from "react";
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Paper from '@material-ui/core/Paper';

import "./AddDevice.css";

export default class AddDevice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      devices:[],
      editmode:false,
      saving:false
    };
  }

  render(){
    return (<Card className="cardPad">

          <div className="AddDiv">

          <h3>Comming soon...</h3>

          <p>Choose the device type. Eg media controls, on/off , rgb lights.</p>
          <p>Assign custom strings/bytes to the control words</p>
          <p>Easilly integrate projects onto alexa....</p>



          </div>








    </Card>)

  }


}
