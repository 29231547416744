import React, { Component } from "react";
import { FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import "./Login.css";
import { Auth } from "aws-amplify";
import LoaderButton from "../components/LoaderButton";
import { Link } from "react-router-dom";
import TextField from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';

export default class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
        isLoading: false,
        email: "",
        password: "",
        showCode:false,
        confirmationCode:"",
        message:""
      };
  }

  validateForm() {
    return this.state.email.length > 0 && this.state.password.length > 0;
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }
handleCode = async event => {
  event.preventDefault();
    this.setState({ isLoading: true });
    try {
        await Auth.confirmSignUp(this.state.email, this.state.confirmationCode);
        this.setState({showCode:false})
        this.setState({ isLoading: false });
    } catch (e) {
    //  alert(e.message);
      console.log(e)
      if(e.code=="UserNotConfirmedException"){
        alert("User not confirmed")
      }
      if(e.code=="NetworkError"){
        alert("Internet Dropped?")
      }
      this.setState({message:e.message})
      this.setState({ isLoading: false });

    }



}



  handleSubmit = async event => {
    event.preventDefault();

    this.setState({ isLoading: true });

    try {
      await Auth.signIn(this.state.email, this.state.password);
      this.props.userHasAuthenticated(true);
    } catch (e) {
    //  alert(e.message);
      console.log(e)
      if(e.code=="UserNotConfirmedException"){
        this.setState({showCode:true})
      }

      this.setState({ isLoading: false });
    }
  }

   resendCode=async event=>{

    console.log("Resend Code")
    try {
      await Auth.resendSignUp(this.state.email);
    } catch (e) {
    //  alert(e.message);
      console.log(e)

    }

  }

  render() {
    return (

        <Card className="cardPad">
        <div className="Login">


        {this.state.showCode==false ? (
        <form onSubmit={this.handleSubmit}>
        <center><h3>Login</h3></center>

        <center>
            <TextField id="standard-basic" id="email" label="Email/username"  value={this.state.email} onChange={this.handleChange}/>
            <TextField id="standard-basic" id="password" label="Password"  value={this.state.password}   type="password" onChange={this.handleChange}/>
  <br/>
  <br/>
          <LoaderButton
            block
            variant="contained"
           color="primary"
            disabled={!this.validateForm()}
            type="submit"
            isLoading={this.state.isLoading}
            text="Login"
            loadingText="Logging in…"
              />
              </center>
              <br/>
                <center><Link to="/login/reset">Forgot password?</Link></center>
                <br/>
        </form>):(<span>
<form onSubmit={this.handleCode}>
  <h3>Code Confirmation</h3>
  <span>Enter confirmation  code sent to email</span>

<TextField  id="confirmationCode" label="Confirmation code"  value={this.state.confirmationCode} onChange={this.handleChange}/>
<br/>
<p>{this.state.message}</p>
<br/><br/>
<LoaderButton
  block
  type="submit"
 color="primary"
 variant="contained"
  isLoading={this.state.isLoading}
  text="Confirm"
  loadingText="Sending"
    />
    <br/>
    <br/>
  <LoaderButton
    block
   color="secondary"
    isLoading={this.state.isLoading}
    text="Resend Code"
    loadingText="Sending"
    onClick={this.resendCode}
      />



</form>
          </span>)}

        </div>

        </Card>

    );
  }
}
