import React, { Component, Fragment } from "react";
import { Link , withRouter} from "react-router-dom";

import { LinkContainer } from "react-router-bootstrap";
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';

import SettingsIcon from '@material-ui/icons/Settings';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import GamesIcon from '@material-ui/icons/Games';

import Routes from "./Routes";
import { Auth, API } from "aws-amplify";
import MenuIcon from '@material-ui/icons/Menu';
import './App.css';


class App extends Component {
  constructor(props) {
  super(props);
  this.state = {
    isAuthenticated: false,
    isAuthenticating: true,
    user:{},
    uid:""
  };
}
handleLogout = async event => {
  await Auth.signOut();

  this.userHasAuthenticated(false);

  this.props.history.push("/login");
}
userHasAuthenticated = authenticated => {
  this.setState({ isAuthenticated: authenticated });
  if(authenticated===true){
    console.log("Attempting API")
      //this.apitest()
  }



}


apitest=async event=>{

  //await this.createNote({
       //content: "hello world"
     //});

}

async userinfo(){

const user = await Auth.currentCredentials();
// /console.log(user)

this.setState({user:user})
console.log(user._identityId)
console.log(user)
return true

}

async componentDidMount() {
  try {
    if (await Auth.currentSession()) {
      this.userHasAuthenticated(true);
      await this.userinfo()
  //    const data = await this.getdata();
  //    this.setState({
  //    "data":data.data
  //  });

    }
  }
  catch(e) {
    console.log(e)
    if (e !== 'No current user') {
      console.log(e)
    //  alert(e);
    }

  }

  this.setState({ isAuthenticating: false });
}
render() {
  const childProps = {
    isAuthenticated: this.state.isAuthenticated,
    userHasAuthenticated: this.userHasAuthenticated,
    user:this.state.user
  };




  return (
    !this.state.isAuthenticating &&
    <div >

  <AppBar className="mainTitle" position="static">
  <Toolbar className="flexTitle">
            {/*<IconButton edge="start"  href="/settings" color="inherit" aria-label="settings">
             <SettingsIcon />
           </IconButton>*/}
           <div>
           <a className="noTextDecor" href="/">
           <span className="mainTitle" >
             OOK
           </span>
           </a>
           </div>
           {this.state.isAuthenticated
             ? <div>
                <IconButton edge="start"  href="/devices" color="inherit" aria-label="settings">
                  <GamesIcon />
                </IconButton>
                <IconButton edge="start"  href="/settings" color="inherit" aria-label="settings">
                  <SettingsIcon />
                </IconButton>
                <IconButton edge="start" onClick={this.handleLogout} color="inherit" aria-label="settings">
                  <ExitToAppIcon />
                </IconButton>
               </div>
             : <div>
                   <Button color="inherit" href="/signup">Signup</Button>
                   <Button color="inherit" href="/login">Login</Button>
               </div>
           }

           </Toolbar>
  </AppBar>

  {/*  <Navbar fluid collapseOnSelect>
  <Navbar.Header>
    <Navbar.Brand>
      <Link to="/">OOK</Link>
    </Navbar.Brand>
    <Navbar.Toggle />
  </Navbar.Header>
  <Navbar.Collapse>
    <Nav pullRight>
      {this.state.isAuthenticated
        ? <Fragment>
            <LinkContainer to="/settings">
              <NavItem>Settings</NavItem>
            </LinkContainer>
            <NavItem onClick={this.handleLogout}>Logout</NavItem>
          </Fragment>
        : <Fragment>
            <LinkContainer to="/signup">
              <NavItem>Signup</NavItem>
            </LinkContainer>
            <LinkContainer to="/login">
              <NavItem>Login</NavItem>
            </LinkContainer>
          </Fragment>
      }
    </Nav>
  </Navbar.Collapse>
</Navbar>*/}
      <Routes childProps={childProps} />
    </div>
  );
}

}


export default withRouter(App);
