import React, { Component } from "react";
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Paper from '@material-ui/core/Paper';
import ReactMarkdown from 'react-markdown'




import "./Markdown.css";




export default class AddDevice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      devices:[],
      editmode:false,
      saving:false,
      md:"### Loading"
    };
  }


  async componentWillMount() {




        let temp=this
        fetch(process.env.PUBLIC_URL+"/md/"+this.props.match.params.id+".md")
          .then(function(response) {
            return response.text();
          }).then(function(data) {
            console.log(data)
            let d={"md":data}
              temp.setState(d)
          });
    }


  render(){
    console.log(this.state.md)
    return (<Card className="cardPad">

          <div className="privpad">

          <h3></h3>

          <ReactMarkdown source={this.state.md} />




          </div>








    </Card>)

  }


}
