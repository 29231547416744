import React, { Component } from "react";
import {
  HelpBlock,
  FormGroup,
  FormControl,
  ControlLabel
} from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import "./Signup.css";
import { Auth } from "aws-amplify";
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

export default class Signup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      username:"",
      email: "",
      password: "",
      confirmPassword: "",
      confirmationCode: "",
      newUser: null
    };
  }
  validateForm() {
    return (
      this.state.email.length > 0 &&
      this.state.password.length > 0 &&
      this.state.password === this.state.confirmPassword
    );
  }

  validateConfirmationForm() {
    return this.state.confirmationCode.length > 0;
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleSubmit = async event => {
  event.preventDefault();

  this.setState({ isLoading: true });

  try {
    const newUser = await Auth.signUp({
      username: this.state.username,
      password: this.state.password,
      attributes:{
          email:this.state.email
      }
    });
    this.setState({
      newUser
    });
  } catch (e) {
    alert(e.message);
  }

  this.setState({ isLoading: false });
}


handleConfirmationSubmit = async event => {
  event.preventDefault();

  this.setState({ isLoading: true });

  try {
    await Auth.confirmSignUp(this.state.username, this.state.confirmationCode);
    await Auth.signIn(this.state.username, this.state.password);

    this.props.userHasAuthenticated(true);
    this.props.history.push("/");
  } catch (e) {
    alert(e.message);
    this.setState({ isLoading: false });
  }
}

  renderConfirmationForm() {
    return (
      <form onSubmit={this.handleConfirmationSubmit}>
          <h3>Confirm code</h3>
        {/*  <ControlLabel>Confirmation Code</ControlLabel>
          <FormControl
            autoFocus
            type="tel"
            value={this.state.confirmationCode}
            onChange={this.handleChange}
          />
          */}
          <p>Please check your email for the code.</p>

          <TextField  id="confirmationCode" label="Confirmation Code"  value={this.state.confirmationCode} type="tel" onChange={this.handleChange}/>

        <br/>
        <br/>
        <LoaderButton
          block
          bsSize="large"
          disabled={!this.validateConfirmationForm()}
          type="submit"
          isLoading={this.state.isLoading}
          text="Verify"
          loadingText="Verifying…"
        />
      </form>
    );
  }

  renderForm() {
    return (
      <form onSubmit={this.handleSubmit}>
        <h3>Register</h3>


        <TextField  id="username" label="Username"  value={this.state.username} type="text" onChange={this.handleChange}/>
        <TextField  id="email" label="Email"  value={this.state.email} type="text" onChange={this.handleChange}/>


        <TextField  id="password" label="Password"  value={this.state.password} type="password" onChange={this.handleChange}/>

        <TextField  id="confirmPassword" label="Current Password"  value={this.state.confirmPassword} type="password" onChange={this.handleChange}/>
<br/><br/>
        <LoaderButton
          block
          bsSize="large"
          disabled={!this.validateForm()}
          type="submit"
          isLoading={this.state.isLoading}
          text="Signup"
          loadingText="Signing up…"
        />
      </form>
    );
  }

  render() {
    return (  <Card className="cardPad">
      <div className="Signup">
      <center>
        {this.state.newUser === null
          ? this.renderForm()
          : this.renderConfirmationForm()}
          </center>
      </div>
      </Card>
    );
  }
}
