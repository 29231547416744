import React, { Component } from "react";
import { Auth } from "aws-amplify";
import { FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import "./ChangePassword.css";
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
export default class ChangePassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      password: "",
      oldPassword: "",
      isChanging: false,
      confirmPassword: ""
    };
  }

  validateForm() {
    return (
      this.state.oldPassword.length > 0 &&
      this.state.password.length > 0 &&
      this.state.password === this.state.confirmPassword
    );
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  handleChangeClick = async event => {
    event.preventDefault();

    this.setState({ isChanging: true });

    try {
      const currentUser = await Auth.currentAuthenticatedUser();
      await Auth.changePassword(
        currentUser,
        this.state.oldPassword,
        this.state.password
      );

      this.props.history.push("/settings");
    } catch (e) {
      alert(e.message);
      this.setState({ isChanging: false });
    }
  };

  render() {
    return (
            <Card className="cardPad">
              <Button className="padback" variant="contained" href="/settings">Back</Button>
      <div className="ChangePassword">
        <center><h3>Change Password</h3></center>
        <center>
        <form onSubmit={this.handleChangeClick}>


<TextField id="standard-basic" id="oldPassword" label="Current Password"  value={this.state.oldPassword} type="password" onChange={this.handleChange}/>




<TextField id="standard-basic" id="password" label="New Password"  value={this.state.password} type="password" onChange={this.handleChange}/>




<TextField id="standard-basic" id="confirmPassword" label="Confirm Password"  value={this.state.confirmPassword} type="password" onChange={this.handleChange}/>
<br/>
<br/>
          <LoaderButton
            block
            variant="outlined" color="secondary"
            type="submit"
            bsSize="large"
            text="Change Password"
            loadingText="Changing…"
            disabled={!this.validateForm()}
            isLoading={this.state.isChanging}
          />
        </form>
        </center>
      </div>
      </Card>
    );
  }
}
