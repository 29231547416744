export default {
  apiGateway: {
    REGION: "eu-west-1",
    URL: "https://rlg41fpl05.execute-api.eu-west-1.amazonaws.com/Development/"
  },
  cognito: {
    REGION: "eu-west-1",
    USER_POOL_ID: "eu-west-1_eDDMJ0K50",
    APP_CLIENT_ID: "40gvqdvcne1pcgqs6ql70ibb1d",
    IDENTITY_POOL_ID: "eu-west-1:fa93df2d-0ff6-4d07-bd82-71db7b064070"
  }
};
