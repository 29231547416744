import React, { Component } from "react";

import "./Devices.css";
import Card from '@material-ui/core/Card';
import { API } from "aws-amplify";
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import TextField from '@material-ui/core/TextField';
import CloseIcon from '@material-ui/icons/Close';

import IconButton from '@material-ui/core/IconButton';
import CheckIcon from '@material-ui/icons/Check';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

import CircularProgress from '@material-ui/core/CircularProgress';



export default class Devices extends Component {
  constructor(props) {
    super(props);
    this.state = {
      devices:[],
      editmode:false,
      saving:false,
      loading:true,
      noOOK:false,
      ookID:""
    };
  }


editMode=(e)=>{

  console.log("Edit Mode Triggered")
  this.setState({editmode:!this.state.editmode})


  if(this.state.editmode==true){
    console.log("edit mode has ended, lets refresh the name")
    this.apitest()
  }
}



addOOKAccount=(e)=>{

    let PostData={"SocketID":this.state.ookID,type:"map"}

    API.post("api", "api",{body:PostData}).then(response => {
      console.log(response)
      this.apitest()
    }).catch(error => {
        console.log(error.response)
    });



}

handleNameDelete=(e)=>{
  console.log("Confirm Name Change")
  let deviceIndex=parseInt(e.currentTarget.dataset.index)
  let data=this.state.devices[deviceIndex]
  let temp=this.state.devices
  temp[deviceIndex].saving=true
  this.setState({devices:temp})

  let PostData={"SocketID":data.socketID,"type":"delete"}

  API.post("api", "api",{body:PostData}).then(response => {
    console.log(response)
    this.apitest()
  }).catch(error => {
      console.log(error.response)
  });


}

handleNameConfirm=(e)=>{
  console.log("Confirm Name Change")
  let deviceIndex=parseInt(e.currentTarget.dataset.index)
  let data=this.state.devices[deviceIndex]
  console.log(data)

  let temp=this.state.devices
  temp[deviceIndex].saving=true
  this.setState({devices:temp})
  //
  console.log(temp)
  let PostData={"SocketID":data.socketID,"type":"rename","Name":data.Name}

  API.post("api", "api",{body:PostData}).then(response => {
    console.log(response)
    temp[deviceIndex].saving=false
    this.setState({devices:temp})
  }).catch(error => {
      console.log(error.response)
      temp[deviceIndex].saving=false
      this.setState({devices:temp})
  });



}


  buttonPress =(e)=>{


    let data=e.currentTarget.dataset
    data.SocketID=data.socketid
        console.log(data)
        API.post("api", "api",{body:data}).then(response => {
          console.log(response)

          }).catch(error => {
              console.log(error.response)
          });
}


handleNameChange=(event)=>{

  console.log(event)
  console.log(event.target)
  console.log(event.target.dataset)
  let deviceIndex=parseInt(event.target.id)

  let tempData=this.state.devices
  tempData[deviceIndex].Name=event.target.value
  this.setState({devices:tempData})

  console.log(event.target.value)


}


  apitest=async event=>{

    let data={"type":"getDevices"}

    API.post("api", "api",{body:data}).then(response => {
      console.log(response)
      this.setState({loading:false})
      this.setState({devices:response})
      }).catch(error => {
          console.log(error.response)
          this.setState({loading:false})
      });


    //await this.createNote({
         //content: "hello world"
       //});

  }

componentWillMount() {


this.apitest()


}


  render(){
  return (
    <div className="deviceDiv">

        <div>

        {this.state.loading==true ? (<Card className="alignCenter" ><center><CircularProgress color="secondary" size={40} /></center></Card>):(<div>


          {this.state.devices.map((l,i) =><Card className="deviceWell" key={l.socketID}>

            {this.state.editmode==true ? (
              <div className="deviceTitle">
                  <TextField  id={i.toString()} label="Device Name" value={l.Name} onChange={this.handleNameChange}/>

                     <IconButton edge="start"   color="inherit" aria-label="save name" data-index={i} onClick={this.handleNameConfirm}>
                      <CheckIcon />
                    </IconButton>

                    <IconButton edge="start"   color="inherit" aria-label="save name" data-index={i} onClick={this.handleNameDelete}>
                     <DeleteForeverIcon />
                   </IconButton>




                                   {l.saving==true ?  <CircularProgress color="secondary" size={20} />:<span></span>}


              </div>
              ):
              (<div className="deviceTitle">{l.Name}</div>)}


            <div>

              <Button color="primary"   onClick={this.buttonPress} data-type="control" data-socketid={l.socketID} data-cmd="TurnOnRequest">ON</Button>
              <Button color="secondary" onClick={this.buttonPress} data-type="control" data-socketid={l.socketID} data-cmd="TurnOffRequest">OFF</Button>
              </div>

          </Card>)}


          </div>)}

          {this.state.noOOK==true ? (

            <Card className="alignCenter" ><center>Register OOK ID:</center></Card>

          ):("")}





          <div className="fabPos">
          <Fab color="secondary" aria-label="edit" onClick={this.editMode}>
            {this.state.editmode ?  <CloseIcon />:<EditIcon />}
          </Fab>&nbsp;
          <Fab color="primary" href="/add" aria-label="add">
            <AddIcon />
          </Fab>
          </div>
        </div>

    </div>
  );
}
}
